body {
  margin: 0;
  font-family: DIN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --table-collapsed-content-background: hsl(0 0% 0% / 0.7);
  --table-collapsed-content-box-shadow: inset 0 0 8px 1px rgb(255 127 28 / 0%);
  --table-collapsed-content-table-header: hsl(214deg 24% 16%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mask {
  opacity: 0.8;
  color: #000;
}

.helper {
  font-size: var(--medium);
  line-height: 1.3;
  color: hsl(0deg 0% 10%) !important;
  font-family: D-DIN !important;
  background-color: hsl(0deg 0% 98%) !important;
}

div[data-tour-elem='controls'] {
  justify-content: space-between;
}
.react-joyride {
  position: absolute;
}
.react-joyride__overlay {
  background-color: var(--color-bg-mask) !important;
}

img[src*='?whatsnew-content-image'] {
  width: 450px;
}

em {
  font-style: italic !important;
}

.form-error-text {
  color: var(--p-color-red);
  text-align: center;
  margin: 12px 0;
}

.react-tiny-popover-container {
  z-index: 100;
}